export default function SoldeIcon() {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 42 37"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M23.9663 5.17567e-05C17.3032 5.17567e-05 11.4685 3.62478 8.34882 9.01657H4.43024C3.60037 9.01657 2.92749 9.68945 2.92749 10.5193C2.92749 11.3492 3.60037 12.0221 4.43024 12.0221H9.24103C9.83777 12.0234 10.2762 11.744 10.4972 11.3616C12.9539 6.41222 18.0506 3.00552 23.9662 3.00552C32.2833 3.00552 38.9937 9.71596 38.9937 18.0331C38.9937 26.3502 32.2833 33.0606 23.9662 33.0606C18.0428 33.0606 12.9386 29.6445 10.4852 24.6837C10.2216 24.2272 9.63187 24.0441 9.24112 24.0441H5.93308C5.10321 24.0441 4.43033 24.7169 4.43033 25.5468C4.43033 26.3767 5.10321 27.0496 5.93308 27.0496H8.35157C11.4715 32.4407 17.3037 36.0661 23.9665 36.0661C33.9078 36.0661 41.9995 27.9747 41.9995 18.033C41.9995 8.09142 33.9081 0 23.9665 0L23.9663 5.17567e-05Z"
				fill="#29129D"
			/>
			<path
				d="M1.50275 16.5303C0.672881 16.5303 0 17.2032 0 18.033C0 18.8629 0.672881 19.5358 1.50275 19.5358H8.97796C9.80784 19.5358 10.4807 18.8629 10.4807 18.033C10.4807 17.2032 9.80784 16.5303 8.97796 16.5303H1.50275Z"
				fill="#29129D"
			/>
			<path
				d="M24.0204 6.04657C23.1905 6.04657 22.5176 6.71945 22.5176 7.54932V9.67997C21.1269 9.95872 20.1695 11.1794 19.6442 12.0897C19.2645 12.8387 19.0975 13.6743 19.1659 14.5112C19.234 15.3481 19.5336 16.1468 20.026 16.8271C20.9236 18.0152 22.1651 18.6153 23.3924 19.219C24.4356 19.699 25.2806 20.1518 25.67 20.6426C26.4445 21.6523 25.5982 23.5542 24.1381 23.5542C22.8627 23.6595 21.8953 22.7501 21.8839 21.8019C21.8366 20.9723 21.1255 20.3387 20.296 20.3873C19.4678 20.4346 18.8345 21.144 18.8811 21.9722C18.9317 22.8581 19.2363 23.7074 19.7498 24.432C20.5556 25.4927 21.4914 26.0391 22.5175 26.3604V28.5176C22.5175 29.3475 23.1904 30.0204 24.0202 30.0204C24.8501 30.0204 25.523 29.3475 25.523 28.5176V26.3282C25.9916 26.1853 26.4398 25.9831 26.8526 25.712C27.5963 25.2236 28.1967 24.5501 28.5841 23.7514C28.9716 22.9527 29.1249 22.0648 29.0246 21.1833C28.9239 20.3018 28.5758 19.4693 28.0237 18.7736C27.0277 17.5188 25.6903 16.9696 24.6485 16.4903C23.5624 15.9355 22.2662 15.3613 22.1626 14.2684C22.0619 13.1671 22.9394 12.5124 23.9062 12.4426C24.9363 12.3647 25.8356 13.0235 26.0107 13.9775C26.103 14.8027 26.8466 15.3967 27.6722 15.3041C28.495 15.2106 29.0871 14.4689 28.9958 13.6458C28.9026 12.8116 28.5799 12.0223 28.0683 11.3565C27.3233 10.4612 26.5068 9.95229 25.5237 9.6337L25.523 7.54934C25.523 6.71947 24.8501 6.04659 24.0202 6.04659L24.0204 6.04657Z"
				fill="#29129D"
			/>
		</svg>
	);
}
