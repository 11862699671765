import DiyIcon from "../../components/ui/icons/DiyIcon";
import { useAuthContext } from "../../context/auth";
import StatisticsDetails from "./statisticsDetails";
import TransactionsHistory from "./TransactionsHistory";

export default function Statistics() {
	const { user } = useAuthContext();
	return (
		<div>
			<div className="mt-0 px-3">
				<div className="d-flex align-items-center justify-content-between company-logo-header">
					<div className="d-flex  align-items-center mb-3">
						<div
							style={{ width: "60px", height: "60px", marginRight: "10px" }}
							className="photo-bg"
						>
							{user?.avatar ? (
								<img
									src={user?.avatar}
									alt="profile-pic"
									style={{ height: "100%", width: "100%", objectFit: "cover" }}
									className="photo-placeholder"
								/>
							) : (
								<div className="bg-white h-100 w-100 photo-placeholder" />
							)}
						</div>
						<h4 className="text-blue ml-1">{user?.companyName}</h4>
						{/* profile photo here */}
					</div>
					<button className="btn text-white bg-blue d-flex align-items-center gap-2 shadow-md">
						<DiyIcon width="15" height="15" />
						<div className="text-white">{user?.bricoleurCount} Bricoleurs</div>
					</button>
				</div>
				<div>
					<h1 className="my-3 fw-bold fs-5">Statistiques</h1>
					<div className="d-flex flex-column flex-lg-row  justify-content-between mb-5 gap-4">
						<StatisticsDetails />
						{/* <div className="chart-wrapper h-full border rounded p-2">
							Chart here
						</div> */}
					</div>
				</div>
				<TransactionsHistory id={user?.id ?? ""} />
			</div>
		</div>
	);
}
