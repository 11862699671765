import { createBrowserRouter } from "react-router-dom";
import MainLayout from "./components/ui/MainLayout";
import Login from "./pages/Login";
import Workers from "./pages/bricoleurs";
import Account from "./pages/account";
import Demands from "./pages/demands";
import Statistics from "./pages/stats";
import Withdraw from "./pages/withdraw";
import NewBricoleur from "./pages/bricoleurs/newBricoleur";

const router = createBrowserRouter([
	{
		path: "/",
		element: <Login />,
	},
	{
		element: <MainLayout />,
		index: false,
		children: [
			{
				path: "/bricoleurs",
				element: <Workers />,
			},
			{ path: "/bricoleurs/new", element: <NewBricoleur /> },
			{
				path: "/account",
				element: <Account />,
			},
			{
				path: "/demands",
				element: <Demands />,
			},
			{
				path: "/stats",
				element: <Statistics />,
			},
			{
				path: "/withdraw",
				element: <Withdraw />,
			},
		],
	},
]);

export default router;
