import React, { useState } from "react";
import StatisticsCard from "../../components/ui/cards/StatisticsCard";
import SoldeIcon from "../../components/ui/icons/SoldeIcon";
import AddBankAccountModal from "../../components/ui/modals/AddBankAccountModal";
import WithdrawModal from "../../components/ui/modals/WithdrawModal";
import { APP_COLORS } from "../../helpers/constants";
import PayoutHistory from "./PayoutHistory";
import { useAuthContext } from "../../context/auth";

export default function Withdraw() {
	const [showAddBankModal, setShowAddBankModal] = useState(false);
	const [showWithdrawModal, setShowWithdrawModal] = useState(false);
	const { user } = useAuthContext();
	return (
		<div>
			<div className="d-flex  align-items-center mb-3">
				<div
					style={{ width: "65px", height: "65px", marginRight: "18px" }}
					className="photo-bg"
				>
					{user?.avatar ? (
						<img
							src={user?.avatar}
							alt="profile-pic"
							style={{ height: "100%", width: "100%", objectFit: "cover" }}
							className="photo-placeholder"
						/>
					) : (
						<div className="bg-white h-100 w-100 photo-placeholder" />
					)}
				</div>
				<h4 className="text-blue ml-2">{user?.companyName}</h4>
				{/* profile photo here */}
			</div>
			<div className="my-4 d-flex gap-3 w-50">
				<div className="w-75">
					<StatisticsCard
						title="Solde courant"
						text={`${"2,256.00"} DH`}
						textColor="#29129D"
						bgColor="#1DAB87"
						icon={<SoldeIcon />}
					/>
				</div>
				<div>
					<button
						className="outline-none border-0 rounded-md w-100 shadow-sm text-white mb-3"
						style={{
							paddingBlock: "0.7rem",
							background: APP_COLORS.yellow,
							// fontSize: "14px",
						}}
						onClick={() => setShowAddBankModal(true)}
					>
						Ajouter un compte bancaire
					</button>
					<button
						className="outline-none border-0 rounded-md w-100 bg-blue text-white rounded-md shadow-sm"
						style={{ padding: "0.7rem" }}
						onClick={() => setShowWithdrawModal(true)}
					>
						Demande de Retrait
					</button>
				</div>
			</div>
			<PayoutHistory />
			<AddBankAccountModal
				visible={showAddBankModal}
				onClose={() => setShowAddBankModal(false)}
			/>
			<WithdrawModal
				visible={showWithdrawModal}
				onClose={() => setShowWithdrawModal(false)}
			/>
		</div>
	);
}
