import { CSpinner } from "@coreui/react";
import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import Pagination from "../../components/Pagination";
import { useCookie } from "../../hooks/useCookie";
import { Messages } from "../../lang/content";
import apiService from "../../service/api.service";

const TRANSACTIONS = [
	{
		id: 1,
		photo:
			"https://via.placeholder.com/150/0000FF/808080 ?Text=Digital.comC/O https://placeholder.com/",
		nom: "user 1",
		job: "Plombier",
		reference: "Q7262GZ",
		createdAt: new Date(),
		prix: 458,
	},
	{
		id: 2,
		photo:
			"https://via.placeholder.com/150/FF0000/FFFFFF?Text=Down.comC/O https://placeholder.com/",
		nom: "user 2",
		job: "Driver",
		reference: "Q7262GZ",
		createdAt: new Date(),
		prix: 458,
	},
	{
		id: 3,
		photo:
			"https://via.placeholder.com/150/FFFF00/000000?Text=WebsiteBuilders.comC/O https://placeholder.com/",
		isVerified: true,
		createdAt: new Date(),
		job: "Electrician",
		reference: "Q7262GZ",
		prix: 458,
		nom: "user 3",
	},
];
const PAGE_SIZE = 4;
export interface Transactions {
	id: number;
	photo: string;
	nom: string;
	job: string;
	reference: string;
	createdAt: Date;
	prix: number;
}
const TransactionTable = ({
	transactionHistory,
}: {
	transactionHistory: Transactions[];
}) => (
	<table className="table table-hover mt-4 transaction-table">
		<thead>
			<tr>
				<th scope="col">Nom</th>
				<th scope="col">Catégorie</th>
				<th scope="col">Référence</th>
				<th scope="col">Prix</th>
				<th scope="col">Date</th>
			</tr>
		</thead>
		<tbody>
			{transactionHistory.map((transaction) => (
				<tr key={transaction.id}>
					<th scope="row" className="d-flex align-items-center">
						<div
							style={{
								width: "30px",
								height: "30px",
								marginRight: "8px",
								marginBlock: "0",
								padding: "1.5px",
							}}
							className="photo-bg"
						>
							{transaction?.photo ? (
								<img
									src={transaction?.photo}
									alt="bricoleur"
									className=" h-100 w-100 rounded-circle"
								/>
							) : (
								<div className=" bg-white h-100 w-100 photo-placeholder align-items-center justify-content-center" />
							)}
						</div>
						<h2 className="fw-bold ml-2 mb-0 fs-6">{transaction?.nom}</h2>
					</th>
					<td>{transaction?.job}</td>
					<td>{transaction?.reference}</td>
					<td>{transaction?.prix}</td>
					<td>{new Date(transaction?.createdAt).toLocaleDateString()}</td>
				</tr>
			))}
		</tbody>
	</table>
);

export default function TransactionsHistory({ id }: { id: string }) {
	const currentDate = new Date();
	const [year, setYear] = useState(currentDate.getFullYear());
	const [month, setMonth] = useState(currentDate.getMonth() + 1);
	const [currentPage, setCurrentPage] = useState(1);
	const { token } = useCookie();
	// fetch offer history
	const { data, isLoading, error } = useQuery("fetchOffers", async () => {
		const offersHistory = await apiService.MakeGetRequest(
			`/companies/offers/history?month=${month}&year=${year}`,
			token
		);
		return offersHistory;
	});
	const preStations = useMemo(() => {
		const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
		const lastPageIndex = firstPageIndex + PAGE_SIZE;
		return data?.slice(firstPageIndex, lastPageIndex);
	}, [currentPage, data]);

	if (error) {
		return (
			<p className="text-center">
				{Messages.fr.content.FailedToLoadTransactions}
			</p>
		);
	}
	return (
		<section className="mt-3">
			<div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
				<h1 className="mb-3 fw-bold fs-5">Historique des prestations</h1>
				<div className="form-group">
					<select
						className="form-control text-center"
						style={{ background: "#EBEAEC" }}
					>
						<option value="Mois">Mois</option>
						<option value="Semaine">Semaine</option>
					</select>
				</div>
			</div>
			{isLoading ? (
				<div className="my-3 d-flex justify-content-center">
					<CSpinner size="sm" color="success" />
				</div>
			) : preStations.length > 0 ? (
				<TransactionTable transactionHistory={preStations} />
			) : (
				<p className="mt-3 text-center">
					Il n&apos;y a pas de transactions à afficher
				</p>
			)}
			<Pagination
				currentPage={currentPage}
				totalCount={data?.length}
				pageSize={PAGE_SIZE}
				onPageChange={(page) => setCurrentPage(page)}
				siblingCount={1}
			/>
		</section>
	);
}
