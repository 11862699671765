interface Props {
	title: string;
	showSearchInput?: boolean;
}

const styles = {
	input: {
		background: "#F2F3F7",
		boxShadow: "1px 1px 20px #f4f4f4",
		width: "300px",
	},
};
export default function AppHeader({ title, showSearchInput = true }: Props) {
	return (
		<div className="d-flex justify-content-between align-items-center">
			<h2 className="fw-bold fs-2 text-blue">{title}</h2>
			{showSearchInput && (
				<form>
					<input
						type="text"
						className="border-0 outline-none p-2 rounded-sm"
						placeholder="Search for a user or DIY"
						style={styles.input}
					/>
				</form>
			)}
		</div>
	);
}
