import { Outlet } from "react-router-dom";
import ProtectedRoute from "../../pages/auth/ProtectedRoute";
import MainSideBar from "./MainSideBar";

export default function MainLayout() {
	return (
		<>
			<ProtectedRoute route="Auth">
				<div className="d-md-flex">
					<div className="w-25 d-none d-md-block">
						<MainSideBar />
					</div>
					<div className="w-100 px-lg-5 py-3 main-layout-bg">
						<Outlet />
					</div>
				</div>
			</ProtectedRoute>
		</>
	);
}
