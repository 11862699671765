import { useCookies } from "react-cookie";
import Modal from ".";
import { useNavigate } from "react-router-dom";

interface Props {
	visible: boolean;
	onClose: () => void;
}
export default function LogoutModal({ visible, onClose }: Props) {
	const [, , removeCookie] = useCookies(["9token"]);
	const navigation = useNavigate();
	const logoutHandler = () => {
		removeCookie("9token");
		navigation("/");
	};
	return (
		<Modal visible={visible} onClose={onClose}>
			<p className="text-center my-2">
				Voulez-vous réellement vous déconnecter ?
			</p>
			<div className="d-flex align-items-center justify-content-center gap-3 mt-4">
				<button
					onClick={onClose}
					className="btn btn-danger red-shadow text-center text-white rounded-sm"
				>
					Annuler
				</button>
				<button
					onClick={logoutHandler}
					className="btn border-success btn-success text-white  text-center rounded-sm"
				>
					Déconnexion
				</button>
			</div>
		</Modal>
	);
}
