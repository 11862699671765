import { CSpinner } from "@coreui/react";
import AppHeader from "../../../components/ui/AppHeader";
import BoxWrapper from "../../../components/ui/BoxWrapper";
import { Link } from "react-router-dom";
import { FormEvent, useState } from "react";
import { useMutation, useQuery } from "react-query";
import apiService from "../../../service/api.service";
import { useCookie } from "../../../hooks/useCookie";
import { toast } from "react-toastify";

interface newUser {
	firstName: string;
	lastname: string;
	role: string;
	phone: string;
	email: string;
	job: string;
}
export default function NewBricoleur() {
	const [generatedPassword, setGeneratedPassword] = useState("");
	const { token } = useCookie();
	// @desc fetch all jobs
	const { data: jobs, isLoading } = useQuery(["fetchAllJobs"], async () => {
		return await apiService.MakeGetRequest("/user/jobs", token);
	});

	// @desc create new user
	const createNewUserMutation = useMutation(async (data: newUser) => {
		return await apiService.MakePostRequest(
			"/companies/bricoleurs",
			data,
			token
		);
	});

	// @desc form submit handler
	const createUserHandler = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);
		const firstName = formData.get("name") as string;
		const lastname = formData.get("lastName") as string;
		const email = formData.get("email") as string;
		const phone = formData.get("phone") as string;
		const job = formData.get("job") as string;
		const role = formData.get("role") as string;
		const data = {
			firstName,
			lastname,
			email,
			phone,
			job,
			role,
		};
		createNewUserMutation.mutate(data, {
			onSuccess(data: any) {
				setGeneratedPassword(data?.password);
				toast.success("Utilisateur créé");
			},
			onError(error: any) {
				toast.error(error?.response?.data?.message);
			},
		});
	};
	return (
		<>
			<AppHeader title="Account" />
			<div className="enterprise mt-4 ">
				<BoxWrapper>
					<section className="p-4">
						<div className="border-bottom border-success">
							<h4>Ajouter bricoleur</h4>
						</div>
						<form className="row form mt-4" onSubmit={createUserHandler}>
							<div className="col-md-6">
								<label htmlFor="name" className="d-block">
									Nom
								</label>
								<input
									type="text"
									className="custom-input"
									placeholder="Nom de l'entreprise"
									id="name"
									name="name"
									// defaultValue={user?.companyName}
								/>
							</div>
							<div className="col-md-6">
								<label htmlFor="lName" className="d-block">
									Prénom
								</label>
								<input
									type="text"
									className="custom-input"
									placeholder="Prénom"
									id="name"
									name="lastName"
								/>
							</div>
							<div className="col-md-6">
								<label htmlFor="email" className="d-block">
									Email
								</label>
								<input
									type="email"
									className="custom-input"
									placeholder="Email"
									id="email"
									name="email"
								/>
							</div>

							<div className="col-md-6">
								<label htmlFor="phone" className="d-block">
									Téléphone
								</label>
								<input
									type="text"
									className="custom-input"
									placeholder="Téléphone"
									id="phone"
									name="phone"
								/>
							</div>
							<div className="col-md-6">
								<label htmlFor="job" className="d-block">
									Emploi
								</label>
								<select name="job" id="job" className="custom-input">
									{isLoading ? (
										<option value="Loading">Loading</option>
									) : (
										jobs?.map((job: Jobs) => (
											<option value={job.id} key={job.id}>
												{job.name}
											</option>
										))
									)}
								</select>
							</div>
							{generatedPassword && (
								<div className="col-md-6">
									<label htmlFor="">Mot de passe: </label>
									{generatedPassword}
								</div>
							)}
							<section className="d-flex justify-content-center buttons">
								<Link
									to="/bricoleurs"
									className="btn btn-danger red-shadow w-20"
								>
									Annuler
								</Link>
								<button
									className="btn btn-success green-shadow w-20"
									type="submit"
									disabled={createNewUserMutation.isLoading}
								>
									{createNewUserMutation.isLoading ? (
										<div className="text-center">
											<CSpinner size="sm" />
										</div>
									) : (
										"Enregistrer"
									)}
								</button>
							</section>
						</form>
					</section>
				</BoxWrapper>
			</div>
		</>
	);
}
