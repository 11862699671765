import { useState } from "react";
import ProfileUpload from "../../components/ProfileUpload";
import AppHeader from "../../components/ui/AppHeader";
import LockIcon from "../../components/ui/icons/LockIcon";
import ChangePasswordModal from "../../components/ui/modals/ChangePasswordModal";
import { useAuthContext } from "../../context/auth";
import AccountForm from "./AccountForm";

export default function Account() {
	const [showPasswordModal, setShowPasswordModal] = useState(false);
	const { user } = useAuthContext();

	return (
		<>
			<AppHeader title="Account" />
			<div className="enterprise mt-4">
				<div className="enterprise__wrapper">
					<div className="d-flex align-items-center justify-content-between border-bottom border-success">
						<h4>Modifier</h4>

						<button
							className="d-flex align-items-center gap-1 outline-none border-0 btn text-gray"
							onClick={() => setShowPasswordModal(true)}
						>
							<LockIcon />
							Changer le mot de passe
						</button>
					</div>
					<div className="d-flex align-items-center flex-column">
						<ProfileUpload photo={user?.avatar} />
						<AccountForm />
					</div>
				</div>
			</div>
			<ChangePasswordModal
				visible={showPasswordModal}
				onClose={() => setShowPasswordModal(false)}
			/>
		</>
	);
}
