import { ChangeEvent, useState } from "react";
import CameraIcon from "./ui/icons/CameraIcon";
import { toast } from "react-toastify";
import { Messages } from "../lang/content";
import { useCookie } from "../hooks/useCookie";
import apiService from "../service/api.service";
import { CSpinner } from "@coreui/react";
import jwtDecode from "jwt-decode";
import { fetchUserProfile } from "../lib/auth";
import { useAuthContext } from "../context/auth";

export default function ProfileUpload({ photo }: { photo?: string | null }) {
	const [uploading, setUploading] = useState(false);
	const { token } = useCookie();
	const { setUser } = useAuthContext();

	const photoUploadHandler = async (e: ChangeEvent<HTMLInputElement>) => {
		const file = e?.target?.files?.[0] as Blob;
		const formData = new FormData();
		formData.append("type", "avatar");
		formData.append("image", file);
		try {
			setUploading(true);
			await apiService.MakePostRequest("/files/avatar", formData, token);
			const decodedValues: any = jwtDecode(token);
			const user = await fetchUserProfile(token, decodedValues.id);
			setUser(user);
		} catch (error) {
			toast.error(Messages.fr.content.FailedToSavePhoto);
		} finally {
			setUploading(false);
		}
	};
	return (
		<div className="photo-bg">
			{photo ? (
				<img
					src={photo}
					alt="profile-pic"
					style={{ height: "100%", width: "100%", objectFit: "cover" }}
					className="photo-placeholder"
				/>
			) : (
				<div className="bg-white h-100 w-100 photo-placeholder" />
			)}
			<div className="file-input">
				<input
					type="file"
					name="photo"
					accept="image/png,image/jpg,image/jpeg"
					onChange={photoUploadHandler}
				/>
				{uploading ? <CSpinner size="sm" color="warning" /> : <CameraIcon />}
			</div>
		</div>
	);
}
