import {
	CFormCheck,
	CSpinner,
	CTable,
	CTableBody,
	CTableDataCell,
	CTableHead,
	CTableHeaderCell,
	CTableRow,
} from "@coreui/react";
import { Dispatch, useEffect, useState } from "react";
import DeleteIcon from "../../components/ui/icons/DeleteIcon";
import DotIcon from "../../components/ui/icons/DotIcon";
import RadioCheckedIcon from "../../components/ui/icons/RadioCheckedIcon";
import OutsideClickHandler from "react-outside-click-handler";
import EditIcon from "../../components/ui/icons/EditIcon";
import apiService from "../../service/api.service";
import { useCookie } from "../../hooks/useCookie";
import { Messages } from "../../lang/content";
import { toast } from "react-toastify";
import { useQueryClient } from "react-query";
import { actionType } from ".";
const styles = {
	popover: {
		top: "20px",
		right: "5%",
		width: "11%",
		zIndex: 30,
	},
};

interface rowProps {
	content: User;
	addWorkerToDeleteList: Dispatch<actionType>;
}
function VerificationCTA({ verified = false, id = "" }) {
	const [isVerified, setIsVerified] = useState(verified);
	const [verifying, setVerifying] = useState(false);
	const { token } = useCookie();

	const toggleVerification = async () => {
		const url = `/companies/bricoleurs/${id}/approve`;
		try {
			setVerifying(true);
			await apiService.MakePutRequest(url, {}, token);
			setIsVerified(true);
		} catch (error) {
			toast.error(Messages.fr.content.FailedToApprove);
		} finally {
			setVerifying(false);
		}
	};

	return (
		<>
			{isVerified ? (
				<button className="btn mx-auto w-100">
					<RadioCheckedIcon />
				</button>
			) : !verifying ? (
				<button
					onClick={toggleVerification}
					className="btn btn-success text-white w-100 green-shadow rounded-full"
				>
					Vérifier
				</button>
			) : (
				<CSpinner size="sm" color="success" />
			)}
		</>
	);
}
function TableBodyRowContent({ content, addWorkerToDeleteList }: rowProps) {
	const [isChecked, setIsChecked] = useState(false);
	const [showActions, setShowActions] = useState(false);
	const { token } = useCookie();
	const queryClient = useQueryClient();

	const removeBricoleurFromCompany = async (id: string) => {
		const url = `/companies/bricoleurs/${id}/remove`;
		try {
			await apiService.MakePutRequest(url, {}, token);
			queryClient.invalidateQueries("fetchWorkers");
			setShowActions(false);
		} catch (error) {
			toast.error(Messages.fr.content.FailedToDelete);
		}
	};
	useEffect(() => {
		if (isChecked) {
			addWorkerToDeleteList({ type: "ADD", id: content.id });
		} else {
			addWorkerToDeleteList({ type: "REMOVE", id: content.id });
		}
	}, [isChecked]);
	return (
		<CTableRow
			style={{
				borderLeft: isChecked ? "3px solid #2acc96" : "",
				width: "100%",
				position: "relative",
			}}
			key={content.id}
		>
			<CTableDataCell className="px-3">
				<div className="d-flex align-items-center gap-3">
					<CFormCheck
						id="flexCheckDefault"
						label=""
						defaultChecked={isChecked}
						onChange={(e) => setIsChecked(e.target.checked)}
						color="#2acc96"
					/>
					{content.avatar ? (
						<img
							src={content.avatar}
							alt=""
							width={30}
							height={30}
							style={{ borderRadius: "100%" }}
						/>
					) : (
						<img
							src="https://via.placeholder.com/150/0000FF/808080 ?Text=Digital.comC/O https://placeholder.com/"
							alt=""
							width={30}
							height={30}
							style={{ borderRadius: "100%" }}
						/>
					)}
					<div>
						<p className="fw-bold m-0">
							{content.firstName} {content.lastName}
						</p>
						<p className="m-0">{content.email}</p>
					</div>
				</div>
			</CTableDataCell>
			<CTableDataCell className="p-3">{content.job.name}</CTableDataCell>
			<CTableDataCell className="p-3">
				{new Date(content.createdAt).toDateString()}
			</CTableDataCell>
			<CTableDataCell className="p-3">
				<VerificationCTA
					verified={content.isApprovedByCompany || false}
					id={content.id}
				/>
			</CTableDataCell>
			<CTableDataCell className="p-3">
				<button className="btn" onClick={() => setShowActions(!showActions)}>
					<DotIcon />
				</button>
			</CTableDataCell>
			{showActions && (
				<OutsideClickHandler
					onOutsideClick={() => {
						setShowActions(false);
					}}
				>
					<div
						className="position-absolute bg-white shadow-sm border p-1 rounded-sm"
						style={styles.popover}
					>
						<div className="d-flex align-items-center gap-2 mb-2 cursor-pointer">
							<EditIcon width="15" height="15" />
							<p className="m-0">Edit</p>
						</div>
						<button
							onClick={() => removeBricoleurFromCompany(content.id)}
							className="d-flex align-items-center gap-2 cursor-pointer bg-transparent border-0 outline-none"
						>
							<div className="rounded-sm">
								<DeleteIcon width="15" height="15" />
							</div>
							<p className="m-0">Delete</p>
						</button>
					</div>
				</OutsideClickHandler>
			)}
		</CTableRow>
	);
}

export default function BricoleursTable({
	currentTableData,
	addWorkerToDeleteList,
}: {
	currentTableData: User[];
	addWorkerToDeleteList: Dispatch<actionType>;
}) {
	return (
		<div className="mt-3">
			<CTable>
				<CTableHead>
					<CTableRow>
						<CTableHeaderCell scope="col">Name</CTableHeaderCell>
						<CTableHeaderCell scope="col">Job</CTableHeaderCell>
						<CTableHeaderCell scope="col">Registration date</CTableHeaderCell>
						<CTableHeaderCell scope="col">Verified</CTableHeaderCell>
						<CTableHeaderCell scope="col"></CTableHeaderCell>
					</CTableRow>
				</CTableHead>
				<CTableBody>
					{currentTableData.map((content) => (
						<TableBodyRowContent
							key={content.id}
							content={content}
							addWorkerToDeleteList={addWorkerToDeleteList}
						/>
					))}
				</CTableBody>
			</CTable>
		</div>
	);
}
