import React, { FormEvent, useEffect, createRef, useState } from "react";
import Modal from ".";
import RadioCheckedIcon from "../icons/RadioCheckedIcon";
import { useMutation, useQuery } from "react-query";
import apiService from "../../../service/api.service";
import { useCookie } from "../../../hooks/useCookie";
import { CSpinner } from "@coreui/react";
import { toast } from "react-toastify";
import { Messages } from "../../../lang/content";

interface Props {
	visible: boolean;
	onClose: () => void;
}
export default function AddBankAccountModal({ visible, onClose }: Props) {
	const { token } = useCookie();
	const [selectedBank, setSelectedBank] = useState<Banks>();
	const [typing, setTyping] = useState<boolean>(false);
	const [validAccount, setValidAccount] = useState<boolean>(false);
	const inputRef = createRef<HTMLInputElement>();
	const { data, isLoading } = useQuery(["fetchBanks"], async () => {
		return await apiService.MakeGetRequest("/banks", token);
	});

	useEffect(() => {
		setSelectedBank((prev) => data?.[0]);
	}, [data]);

	const addAccountMutation = useMutation(
		async ({ bank }: { bank: { RIB: string; bank?: number } }) => {
			await apiService.MakePutRequest("/user/bank-account", bank, token);
		}
	);

	const onBankSelect = (selectedBank: string) => {
		const selectedAccount = data?.filter(
			(banks: Banks) => banks.id === Number(selectedBank)
		)[0];
		setSelectedBank((prev) => selectedAccount);
	};

	const addAccountHandler = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const RIB = inputRef?.current?.value as string;

		addAccountMutation.mutate(
			{ bank: { RIB, bank: selectedBank?.id } },
			{
				onSuccess() {
					toast.success(Messages.fr.account.RIBAdded);
					onClose();
				},
				onError(error) {
					console.log(error);
					toast.error(Messages.fr.account.FailedToAddRIB);
				},
			}
		);
	};

	return (
		<Modal visible={visible} onClose={onClose}>
			<div className="d-flex flex-column  gap-2 w-100 mb-3">
				{isLoading ? (
					<CSpinner size="sm" color="success" />
				) : (
					<form className="px-5" onSubmit={addAccountHandler}>
						<div className="mb-3">
							<label htmlFor="account-number" className="fw-bold mb-1">
								Numéro de compte
							</label>
							<div
								className="d-flex align-items-center justify-content-between border rounded-sm"
								style={{ padding: "0.7rem" }}
							>
								<img
									src={selectedBank?.logo}
									alt=""
									width={20}
									height={20}
									style={{ objectFit: "cover" }}
								/>
								<select
									name="accountNumber"
									id="account-number"
									className="bg-transparent w-100 border-0 outline-none"
									onChange={(e) => onBankSelect(e.currentTarget.value)}
								>
									{data?.map((bank: Banks) => (
										<option value={bank.id} key={bank.id}>
											{bank.name}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="mb-3">
							<label htmlFor="account-number" className="fw-bold mb-1">
								Numéro de compte
							</label>
							<div className="d-flex align-items-center justify-content-between border rounded-sm p-2">
								<input
									type="text"
									name="accountNumber"
									id="account-number"
									className="bg-transparent w-100 border-0 outline-none"
									maxLength={24}
									ref={inputRef}
									onChange={(e) =>
										e.currentTarget.value.length === 24
											? (() => {
													setValidAccount(true);
													setTyping(false);
											  })()
											: e.currentTarget.value.length === 0
											? (() => {
													setValidAccount(false);
													setTyping(false);
											  })()
											: (() => {
													setValidAccount(false);
													setTyping(true);
											  })()
									}
								/>
								{validAccount ? (
									<RadioCheckedIcon width="18" height="18" />
								) : (
									typing && <CSpinner color="success" size="sm" />
								)}
							</div>
						</div>
						<button
							className="btn btn-success w-100 text-white rounded-pill mt-2"
							type="submit"
							disabled={typing || !validAccount || addAccountMutation.isLoading}
						>
							{addAccountMutation.isLoading ? (
								<CSpinner size="sm" />
							) : (
								"Enregistrer"
							)}
						</button>
					</form>
				)}
			</div>
		</Modal>
	);
}
