export default function WithdrawIcon({ fill = "white" }) {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 45 56"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M34.7386 32.6474C40.5373 33.1463 45 38.015 45 43.8417C45 50.0459 39.9691 55.0769 33.7648 55.0769C27.0508 55.0769 21.8654 49.2411 22.5977 42.5971H5.01162V13.2582H0V0H39.7516V13.2582H34.7396V32.6463L34.7386 32.6474ZM33.1545 47.8365V44.2821C28.5512 42.9656 29.1381 37.4747 33.1545 37.0773V36.2417H34.3908V37.0773C36.1461 37.2852 37.2706 38.2809 37.5772 40.037L35.418 40.3157C35.285 39.6482 34.9421 39.1959 34.3912 38.9574V42.2747C39.7822 43.7169 38.5787 49.2 34.3912 49.8564V51.4406H33.1549V49.874C31.0415 49.6162 29.9202 48.2644 29.6035 46.2178L31.8146 45.9567C31.9664 46.7237 32.4346 47.4873 33.1549 47.8368L33.1545 47.8365ZM33.1545 38.9231C31.7352 39.4289 31.6894 41.2382 33.1545 41.8656V38.9231ZM34.3908 47.9764C36.1502 47.5925 36.5107 45.2594 34.3908 44.648V47.9764ZM15.7151 4.31178H24.0346L19.8747 8.94602L15.7147 4.31178H15.7151ZM30.0472 13.2583C30.073 13.3725 30.0869 13.4912 30.0869 13.6123V18.0366C30.0869 18.9271 29.358 19.656 28.4667 19.656H24.0427C23.1513 19.656 22.4233 18.9271 22.4233 18.0366V13.6123C22.4233 13.4912 22.4364 13.3725 22.4622 13.2583H18.5609V40.066H23.1779C24.5784 36.1385 28.0668 33.2856 32.2079 32.7119V13.2578L30.0472 13.2583ZM7.54121 40.066H10.1072V13.2583H7.54121V40.066ZM37.2185 2.52917H2.52917V10.7267H37.2185V2.52917ZM33.7633 35.1357C22.3133 35.1357 22.3133 52.5458 33.7633 52.5458C38.5711 52.5458 42.4683 48.6486 42.4683 43.8408C42.4683 39.0325 38.5711 35.1357 33.7633 35.1357Z"
				fill={fill}
			/>
		</svg>
	);
}
