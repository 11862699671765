import Modal from ".";
import RadioCheckedIcon from "../icons/RadioCheckedIcon";
import { useMutation, useQuery } from "react-query";
import apiService from "../../../service/api.service";
import { useCookie } from "../../../hooks/useCookie";
import { CSpinner } from "@coreui/react";
import { FormEvent, useEffect, useState } from "react";
import { toast } from "react-toastify";

interface Props {
	visible: boolean;
	onClose: () => void;
}
export default function WithdrawModal({ visible, onClose }: Props) {
	const { token } = useCookie();
	const [selectedAccount, setSelectedAccount] = useState<Account>();
	const { data, isLoading, error } = useQuery(["fetchAccounts"], async () => {
		return await apiService.MakeGetRequest("/user/bank-accounts", token);
	});

	const requestWithdrawMutation = useMutation(
		async ({ account, amount }: { account?: string; amount: number }) => {
			await apiService.MakePutRequest(
				"/user/withdrawal",
				{ account, amount },
				token
			);
		}
	);
	useEffect(() => {
		setSelectedAccount(data?.accounts?.[0]);
	}, [data]);

	if (error) return <p className="text-center my-1">Erreur !!</p>;

	const withdrawHandler = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);
		const amount = formData.get("amount") as string;
		const account = selectedAccount?.id;
		requestWithdrawMutation.mutate(
			{ account, amount: Number(amount) },
			{
				onSuccess() {
					onClose();
					toast.success("Request completed");
				},
				onError(error: any) {
					toast.error(error?.response?.data?.message);
				},
			}
		);
	};

	return (
		<Modal visible={visible} onClose={onClose}>
			<div className="d-flex flex-column align-items-center  gap-2 w-100 mb-3">
				{isLoading ? (
					<CSpinner color="success" size="sm" />
				) : (
					<form className="px-5" onSubmit={withdrawHandler}>
						<div className="mb-3">
							<label htmlFor="account-number" className="fw-bold mb-1">
								Compte bancaire
							</label>
							<div
								className="d-flex align-items-center justify-content-between border rounded-sm"
								style={{ padding: "0.7rem" }}
							>
								<img
									src={selectedAccount?.bank.logo}
									alt={selectedAccount?.bank.name}
									width={20}
									height={20}
								/>
								<select
									name="accountNumber"
									id="account-number"
									className="bg-transparent w-100 border-0 outline-none"
								>
									{data?.accounts?.map((account: Account) => (
										<option value={account.RIB}>{account.RIB}</option>
									))}
								</select>
							</div>
						</div>
						<div className="mb-3">
							<label htmlFor="account-number" className="fw-bold mb-1">
								Montant
							</label>
							<div className="d-flex">
								<input
									type="number"
									name="amount"
									id="account-number"
									className="bg-transparent w-100  border p-2 rounded-sm outline-none"
									min={1}
								/>
								<div
									className="d-flex align-items-center justify-content-center text-center text-white h-100 w-20 rounded-sm p-2"
									style={{
										background:
											"linear-gradient(137.5deg, #FF8008 5.41%, #FFC837 74.59%)",
									}}
								>
									DH
								</div>
							</div>
						</div>
						<button
							className="btn btn-success w-100 text-white rounded-pill mt-2"
							type="submit"
							disabled={requestWithdrawMutation.isLoading}
						>
							{requestWithdrawMutation.isLoading ? (
								<CSpinner size="sm" />
							) : (
								"Continuer"
							)}
						</button>
					</form>
				)}
			</div>
		</Modal>
	);
}
