import { CModal, CModalBody } from "@coreui/react";
import { ReactNode } from "react";

interface Props {
	visible: boolean;
	onClose: () => void;
	children: ReactNode;
}
export default function Modal({ visible, onClose, children }: Props) {
	return (
		<CModal visible={visible} onClose={onClose}>
			<button
				onClick={onClose}
				className="position-absolute border-0 outline-none bg-transparent"
				style={{ top: "-2%", right: "-3%", zIndex: 99 }}
			>
				<svg
					width="30"
					height="30"
					viewBox="0 0 70 70"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					onClick={() => onClose()}
					className="cursor-pointer"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M35 0C54.3672 0 70 15.7195 70 35C70 54.3672 54.3672 70 35 70C15.7195 70 0 54.3672 0 35C0 15.7195 15.7195 0 35 0ZM35 42.1208L23.3626 53.7583C18.6728 58.4481 11.6379 51.3266 16.2411 46.7235L27.9657 34.9988L16.2411 23.3614C11.6379 18.6716 18.6728 11.6368 23.3626 16.2399L35 27.9645L46.7246 16.2399C51.3278 11.6368 58.4493 18.6716 53.7594 23.3614L42.122 34.9988L53.7594 46.7235C58.4493 51.3266 51.3278 58.4481 46.7246 53.7583L35 42.1208Z"
						fill="#2ACC96"
					/>
				</svg>
			</button>
			<CModalBody className="change-password-modal py-5 ">
				{children}
			</CModalBody>
		</CModal>
	);
}
