import { ReactNode } from "react";

interface Props {
	bgColor: string;
	textColor: string;
	icon?: ReactNode;
	title: string;
	text: string;
}
export default function StatisticsCard({
	bgColor,
	icon,
	textColor,
	title,
	text,
}: Props) {
	return (
		<div
			className="shadow-lg w-100 p-3 position-relative"
			style={{
				backgroundColor: bgColor,
				borderRadius: "10px",
			}}
		>
			<div
				className="position-absolute top-0 left-0"
				style={{ bottom: "0", height: "40px" }}
			>
				<svg
					width="300"
					height="120"
					viewBox="0 0 300 150"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<mask
						id="mask0_6131_8615"
						// style="mask-type:alpha"
						maskUnits="userSpaceOnUse"
						x="0"
						y="0"
						width="300"
						height="150"
					>
						<rect width="300" height="150" rx="16" fill="#1DAB87" />
					</mask>
					<g mask="url(#mask0_6131_8615)">
						<path
							opacity="0.3"
							d="M42.3401 -75C27.3417 -35.0869 -2.10023 58.4674 0.118698 113.38C2.89236 182.02 38.3704 166.519 43.5727 162.191C47.2209 159.155 86.1022 111.092 93.1905 61.5181C100.279 11.9445 78.6872 16.4936 76.5485 18.0458C67.3004 24.7578 53.1264 39.4006 39.5663 104.228C26.0061 169.055 23.8488 217.103 33.7108 239.983C41.6003 258.287 59.8472 256.644 76.7407 253.526C106.115 248.104 129.741 202.155 145.274 175.919C160.806 149.683 219.341 46.5189 246.667 -1.78361"
							stroke="#29129D"
							strokeWidth="0.5"
							strokeLinecap="round"
						/>
					</g>
				</svg>
			</div>
			<div className="d-flex align-items-center justify-content-between">
				<p className="text-white">{title}</p>
				{icon && icon}
			</div>
			<p
				className="fw-bold my-2"
				style={{ color: textColor, fontSize: "1.3rem" }}
			>
				{text}
			</p>
		</div>
	);
}
