import { useMemo, useState } from "react";
import Pagination from "../../components/Pagination";
import { getOperationStatusColor } from "../../helpers/getOperationStatusColor";
import { useQuery } from "react-query";
import apiService from "../../service/api.service";
import { useCookie } from "../../hooks/useCookie";
import { CSpinner } from "@coreui/react";

const PAGE_SIZE = 4;

const TransactionTable = ({ payouts }: { payouts: Payouts[] }) => (
	<table className="table table-hover mt-4 transaction-table">
		<thead>
			<tr>
				<th scope="col">Référence</th>
				<th scope="col">Montant</th>
				<th scope="col">Banque</th>
				<th scope="col">Status</th>
				<th scope="col">Date</th>
			</tr>
		</thead>
		<tbody>
			{payouts.map((payout) => (
				<tr key={payout.id}>
					<td>{payout?.reference}</td>
					<td>{payout?.montant}</td>
					<td>{"CIH"}</td>
					<td style={{ color: getOperationStatusColor(payout?.statut) }}>
						{payout?.statut}
					</td>
					<td>{new Date(payout?.createdAt).toLocaleDateString()}</td>
				</tr>
			))}
		</tbody>
	</table>
);

export default function PayoutHistory() {
	const currentDate = new Date();
	const [year, setYear] = useState(currentDate.getFullYear());
	const [month, setMonth] = useState(currentDate.getMonth() + 1);

	const [currentPage, setCurrentPage] = useState(1);
	const { token } = useCookie();

	const { data, isLoading, error } = useQuery(["fetchPayout"], async () => {
		return await apiService.MakeGetRequest(
			`/companies/payouts?month=${month}&year=${year}`,
			token
		);
	});

	const payouts = useMemo(() => {
		const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
		const lastPageIndex = firstPageIndex + PAGE_SIZE;
		return data?.slice(firstPageIndex, lastPageIndex);
	}, [currentPage, data]);
	if (error) return <p className="text-center my-2 text-danger">Erreur !!</p>;
	return (
		<section className="mt-5">
			<div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
				<h1 className="mb-3 fw-bold fs-5">Historique des transactions</h1>
				<div className="d-flex align-items-center gap-3">
					<div className="form-group">
						<select
							className="form-control text-center"
							style={{ background: "#EBEAEC" }}
							onChange={(e) => setYear(Number(e.target.value))}
							defaultValue={month}
						>
							<option value="Mois" disabled>
								Mois
							</option>
							<option value="1">Jan</option>
							<option value="2">Feb</option>
						</select>
					</div>
					<div className="form-group">
						<select
							className="form-control text-center"
							style={{ background: "#EBEAEC" }}
							onChange={(e) => setMonth(Number(e.target.value))}
							defaultValue={year}
						>
							<option value="Année" disabled>
								Année
							</option>
							<option value="2022">2022</option>
							<option value="2023">2023</option>
						</select>
					</div>
				</div>
			</div>
			{isLoading ? (
				<CSpinner size="sm" color="success" />
			) : payouts?.length > 0 ? (
				<TransactionTable payouts={payouts} />
			) : (
				<p className="mt-3 text-center">
					Il n&apos;y a pas de transactions à afficher
				</p>
			)}
			{!isLoading && (
				<Pagination
					currentPage={currentPage}
					totalCount={data?.length}
					pageSize={PAGE_SIZE}
					onPageChange={(page) => setCurrentPage(page)}
					siblingCount={1}
				/>
			)}
		</section>
	);
}
