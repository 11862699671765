import React from "react";

export default function LockIcon({ fill = "#8E8E8E" }) {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M14 8H13.3333V5.33333C13.3333 2.392 10.9413 0 8 0C5.05867 0 2.66667 2.392 2.66667 5.33333V8H2C0.897778 8 0 8.89689 0 10V19.3333C0 20.4364 0.897778 21.3333 2 21.3333H14C15.1022 21.3333 16 20.4364 16 19.3333V10C16 8.89689 15.1022 8 14 8ZM4.44444 5.33333C4.44444 3.37244 6.03911 1.77778 8 1.77778C9.96089 1.77778 11.5556 3.37244 11.5556 5.33333V8H4.44444V5.33333ZM8.88889 14.864V16.8889C8.88889 17.3796 8.49156 17.7778 8 17.7778C7.50844 17.7778 7.11111 17.3796 7.11111 16.8889V14.864C6.58222 14.5556 6.22222 13.9884 6.22222 13.3333C6.22222 12.3529 7.01956 11.5556 8 11.5556C8.98044 11.5556 9.77778 12.3529 9.77778 13.3333C9.77778 13.9884 9.41778 14.5556 8.88889 14.864Z"
				fill={fill}
			/>
		</svg>
	);
}
