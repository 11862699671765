import { FormEvent, useEffect, useState } from "react";
import LockIcon from "../icons/LockIcon";
import { APP_COLORS } from "../../../helpers/constants";
import Modal from ".";
import { Messages } from "../../../lang/content";
import apiService from "../../../service/api.service";
import { useCookie } from "../../../hooks/useCookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CSpinner } from "@coreui/react";

interface Props {
	visible: boolean;
	onClose: () => void;
}
export default function ChangePasswordModal({ visible, onClose }: Props) {
	const [passwordError, setPasswordError] = useState<string | null>(null);
	const [loggingOut, setLoggingOut] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const { token, removeCookie } = useCookie();

	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			setPasswordError(null);
		}, 3000);
	}, [passwordError]);
	// @desc: handle onsubmit
	const updatePasswordHandler = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);
		const ancienPassword = formData.get("old-password");
		const newPassword = formData.get("new-password")?.toString();
		const confirmPassword = formData.get("confirm-password")?.toString();

		if (!newPassword?.includes(confirmPassword || "")) {
			setPasswordError(Messages.fr.password.misMatch);
			return;
		}

		try {
			setIsSaving(true);
			await apiService.MakePatchRequest(
				"/user/changepassword",
				{ ancienPassword, newPassword, confirmPassword },
				token
			);
			setLoggingOut(true);
			onClose();
			setTimeout(() => {
				removeCookie("9token");
				navigate("/");
			}, 2000);
		} catch (error: any) {
			toast.error(error?.response?.data?.message);
		} finally {
			setIsSaving(false);
		}
	};

	if (loggingOut) {
		return (
			<div className="logout-wrapper">
				<div className="d-flex flex-column h-100 align-items-center justify-content-center">
					<CSpinner color="success" />
					<p className="text-white mt-1">{Messages.fr.content.loggingInOut}</p>
				</div>
			</div>
		);
	}
	return (
		<Modal visible={visible} onClose={onClose}>
			<div className="d-flex flex-column  gap-2 w-100 mb-3">
				<h5 className="fs-5 text-center mb-3 ">Changer le mot de passe</h5>
				<form onSubmit={updatePasswordHandler} className="px-5">
					<div className="d-flex align-items-center gap-2 form-control mb-3">
						<LockIcon fill={APP_COLORS.green} />
						<input
							type="password"
							name="old-password"
							className="text-md text-gray border-0 outline-none w-100 bg-transparent"
							placeholder="Ancien mot de passe"
						/>
					</div>
					<div className="d-flex align-items-center gap-2 form-control mb-3">
						<LockIcon fill={APP_COLORS.green} />
						<input
							type="password"
							name="new-password"
							className="text-md text-gray border-0 outline-none w-100 bg-transparent"
							placeholder="Nouveau mot de passe"
						/>
					</div>
					<div className="d-flex align-items-center gap-2 form-control mb-3">
						<LockIcon fill={APP_COLORS.green} />
						<input
							type="password"
							name="confirm-password"
							className="text-md text-gray border-0 outline-none w-100 bg-transparent"
							placeholder="Confirmez le mot de passe"
						/>
					</div>
					{passwordError && (
						<p
							className="my-1 text-danger text-center"
							style={{ fontSize: "14px" }}
						>
							{passwordError}
						</p>
					)}
					<button
						className="btn btn-success w-100 text-white rounded-pill mt-2"
						type="submit"
					>
						{isSaving ? (
							<div className="text-center">
								<CSpinner size="sm" />
							</div>
						) : (
							"Valider"
						)}
					</button>
				</form>
			</div>
		</Modal>
	);
}
