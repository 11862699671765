import { CSpinner } from "@coreui/react";

export default function Loader({
	className = "w-100 h-100 d-flex align-items-center justify-content-center",
	color = "success",
}) {
	return (
		<div className={className}>
			<CSpinner color={color} />
		</div>
	);
}
