export const APP_COLORS = {
	green: "#2acc96",
	red: "#fc376b",
	gray: "#979797",
	yellow: "#FFB800",
};

export const DEMAND_STATUS = {
	cancelled: "Annulée",
	finished: "Terminé",
	published: "Publiée",
};
export const TRANSACTIONS_STATUS = {
	processing: "En attente",
	completed: "Éffectuée",
	cancelled: "Annulée",
};
