import { CookiesProvider } from "react-cookie";
import { QueryClient, QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router-dom";
import AuthContextProvider from "./context/auth";
import router from "./route";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import "./sass/main.scss";

const queryClient = new QueryClient();
function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<AuthContextProvider>
				<CookiesProvider>
					<RouterProvider router={router} />
					<ToastContainer theme="colored" position="top-right" />
				</CookiesProvider>
			</AuthContextProvider>
		</QueryClientProvider>
	);
}

export default App;
