import { useMemo, useReducer, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import AppHeader from "../../components/ui/AppHeader";
import BoxWrapper from "../../components/ui/BoxWrapper";
import DeleteIcon from "../../components/ui/icons/DeleteIcon";
import DiyIcon from "../../components/ui/icons/DiyIcon";
import { useCookie } from "../../hooks/useCookie";
import { Messages } from "../../lang/content";
import apiService from "../../service/api.service";
import BricoleursTable from "./BricoleursTable";
import { toast } from "react-toastify";
import { CSpinner } from "@coreui/react";
const PAGE_SIZE = 8;

const initialState: { ids: string[] } = { ids: [] };
export type actionType =
	| { type: string; id: string }
	| { id: string; type: string };
export default function Workers() {
	const workersReducer = (state: typeof initialState, action: actionType) => {
		if (action?.type === "ADD") {
			return {
				ids: [...state.ids, action.id],
			};
		} else if (action?.type === "REMOVE") {
			const filteredResult = state.ids.filter((id) => id !== action.id);
			return {
				ids: filteredResult,
			};
		} else {
			return {
				ids: [],
			};
		}
	};
	const [currentPage, setCurrentPage] = useState(1);
	const [workersToBeDeleted, dispatch] = useReducer(
		workersReducer,
		initialState
	);
	const { token } = useCookie();
	const queryClient = useQueryClient();
	// fetch all  workers
	const { data: bricoleurs, isLoading } = useQuery(
		["fetchWorkers", token],
		async (query) => {
			return apiService.MakeGetRequest(
				"/companies/bricoleurs",
				query.queryKey[1]
			);
		}
	);
	// delete bulk mutation
	const bulkDelete = useMutation(
		async ({ id }: { id: string }) => {
			const url = `/companies/bricoleurs/${id}/remove`;
			await apiService.MakePutRequest(url, {}, token);
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries("fetchWorkers");
				dispatch({ type: "", id: "" });
			},
			onError: () => {
				toast.error(Messages.fr.content.FailedToDelete);
			},
		}
	);

	// create paginated content
	const currentTableData: User[] = useMemo(() => {
		const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
		const lastPageIndex = firstPageIndex + PAGE_SIZE;
		return bricoleurs?.slice(firstPageIndex, lastPageIndex);
	}, [bricoleurs, currentPage]);

	// handle bulk delete
	const handleBulkDelete = async () => {
		workersToBeDeleted.ids.forEach(async (id) => {
			if (!id) return;

			bulkDelete.mutate({ id });
		});
	};
	return (
		<>
			<AppHeader title="Bricoleurs" />
			<BoxWrapper>
				<div className="d-flex  justify-content-between">
					<div className="d-flex align-items-center gap-2 p-3 w-50">
						<Link
							to="/bricoleurs/new"
							className="outline-none border-0 btn btn-success rounded-full text-white w-100 d-flex align-items-center gap-2 justify-content-center green-shadow"
						>
							+ Add DIY
						</Link>
						{bulkDelete.isLoading ? (
							<CSpinner size="sm" color="" />
						) : (
							<button
								disabled={workersToBeDeleted.ids.length === 0}
								onClick={handleBulkDelete}
								className="outline-none  btn btn-outline-danger rounded-full text-danger w-100 d-flex align-items-center gap-2 justify-content-center red-shadow"
							>
								<DeleteIcon /> Delete DIY
							</button>
						)}
					</div>

					<div
						className="d-flex align-items-center justify-content-center gap-3 green-gradient p-3"
						style={{ borderTopRightRadius: "8px" }}
					>
						<DiyIcon fill="black" width="25" height="25" />
						<p className="fs-5 fw-bold m-0">{currentTableData?.length} DIY</p>
					</div>
				</div>
				{isLoading ? (
					<Loader />
				) : bricoleurs.length > 0 ? (
					<BricoleursTable
						currentTableData={currentTableData}
						addWorkerToDeleteList={dispatch}
					/>
				) : (
					<p className="text-center mt-4 pb-5">
						{Messages.fr.content.NoWorkers}
					</p>
				)}
			</BoxWrapper>
			{!isLoading && (
				<Pagination
					currentPage={currentPage}
					totalCount={bricoleurs.length}
					pageSize={PAGE_SIZE}
					onPageChange={(page) => setCurrentPage(page)}
					siblingCount={1}
				/>
			)}
		</>
	);
}
