import { CSpinner } from "@coreui/react";
import { FormEvent } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../context/auth";
import { useCookie } from "../../hooks/useCookie";
import apiService from "../../service/api.service";
import { toast } from "react-toastify";

export default function AccountForm() {
	const [isSaving, setIsSaving] = useState(false);
	const { user } = useAuthContext();
	const { token } = useCookie();

	const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);
		const name = formData.get("name");
		const email = formData.get("email");
		const address = formData.get("address");
		const phone = formData.get("phone");
		const city = formData.get("city");
		const description = formData.get("description");

		const data = { name, email, phone, city, address, description };
		try {
			setIsSaving(true);
			const result = await apiService.MakePutRequest("/companies", data, token);
			console.log(result);
			toast.success("Account updated");
		} catch (error) {
			console.log(error);
			toast.error("Failed to update account ");
		} finally {
			setIsSaving(false);
		}
	};

	return (
		<form onSubmit={handleFormSubmit}>
			<div className="row form">
				<div className="col-md-6">
					<label htmlFor="name">Nom de l&apos;entreprise</label>
					<input
						type="text"
						className="custom-input"
						placeholder="Nom de l'entreprise"
						id="name"
						name="name"
						defaultValue={user?.companyName}
					/>
				</div>

				<div className="col-md-6">
					<label htmlFor="email">Email</label>
					<input
						type="text"
						className="custom-input"
						placeholder="Email"
						name="email"
						id="email"
						defaultValue={user?.email}
						disabled
					/>
				</div>
				<div className="col-md-6">
					<label htmlFor="address">Addresse</label>
					<input
						type="text"
						className="custom-input"
						placeholder="Address"
						name="address"
						id="address"
						defaultValue={user?.address}
					/>
				</div>
				<div className="col-md-6">
					<label htmlFor="phone">Téléphone</label>
					<input
						type="text"
						className="custom-input"
						placeholder="Téléphone"
						name="phone"
						id="phone"
						defaultValue={user?.phone}
					/>
				</div>
				<div className="col-md-6">
					<label htmlFor="city" id="city">
						Ville
					</label>
					<input
						type="text"
						className="custom-input"
						name="city"
						placeholder="Ville"
						id="city"
						defaultValue={user?.city.label}
					/>
				</div>
			</div>

			<section className="d-flex justify-content-center buttons">
				<Link to="/bricoleurs" className="btn btn-danger red-shadow w-20">
					Annuler
				</Link>
				<button
					className="btn btn-success green-shadow w-20"
					type="submit"
					disabled={isSaving}
				>
					{isSaving ? (
						<div className="text-center">
							<CSpinner size="sm" />
						</div>
					) : (
						"Enregistrer"
					)}
				</button>
			</section>
		</form>
	);
}
