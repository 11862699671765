import React from "react";

export default function DemandIcon({
	fill = "white",
	width = "25",
	height = "25",
}) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 46 43"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M34.1248 0C34.6961 0 35.1592 0.463083 35.1592 1.03436V3.44697H42.7422C44.2651 3.44697 45.5 4.68183 45.5 6.20476V39.9847C45.5 41.5076 44.2651 42.7425 42.7422 42.7425H2.75779C1.23486 42.7425 0 41.5076 0 39.9847V6.20476C0 4.68183 1.23486 3.44697 2.75779 3.44697H10.3408V1.03436C10.3408 0.463083 10.8039 0 11.3752 0C11.946 0 12.409 0.463083 12.409 1.03436V3.44697H33.0906V1.03436C33.0906 0.463083 33.5537 0 34.1245 0H34.1248ZM43.4318 14.4771H2.06861V39.9844C2.06861 40.3379 2.33454 40.6293 2.6774 40.6692L2.75819 40.674H42.7426C43.0965 40.674 43.388 40.4076 43.4274 40.0647L43.4322 39.9844L43.4318 14.4771ZM42.7422 5.51497H35.1592V7.23844C35.1592 7.80972 34.6961 8.2728 34.1249 8.2728C33.5541 8.2728 33.091 7.80972 33.091 7.23844V5.51497H12.4094V7.23844C12.4094 7.80972 11.9463 8.2728 11.3755 8.2728C10.8042 8.2728 10.3411 7.80972 10.3411 7.23844V5.51497H2.75812C2.4042 5.51497 2.11278 5.78138 2.07335 6.12424L2.06854 6.20454V12.4088H43.4317V6.20454C43.4317 5.82368 43.123 5.51496 42.7421 5.51496L42.7422 5.51497ZM29.341 22.288C29.7863 21.9307 30.4374 22.0019 30.7948 22.4472C31.13 22.865 31.0881 23.4628 30.7145 23.8306L30.6356 23.9008L19.4672 32.863C19.0493 33.1986 18.4506 33.1563 18.0832 32.7817L18.0126 32.7024L14.7036 28.5663C14.3468 28.12 14.4194 27.4694 14.8652 27.1125C15.2831 26.7783 15.8813 26.8206 16.2482 27.1948L16.3184 27.2741L18.981 30.6018L29.341 22.288Z"
				fill={fill}
			/>
		</svg>
	);
}
