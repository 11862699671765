import {
	CFormCheck,
	CSpinner,
	CTable,
	CTableBody,
	CTableDataCell,
	CTableHead,
	CTableHeaderCell,
	CTableRow,
} from "@coreui/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { DEMAND_STATUS } from "../../helpers/constants";
import { getOperationStatusColor } from "../../helpers/getOperationStatusColor";
import { useMutation, useQueryClient } from "react-query";
import { useCookie } from "../../hooks/useCookie";
import apiService from "../../service/api.service";
import { toast } from "react-toastify";

function TableBodyRowContent({ demand }: { demand: Demands }) {
	const [isChecked, setIsChecked] = useState(false);

	const { token } = useCookie();
	const queryClient = useQueryClient();
	const approveDemandMutation = useMutation(
		async () => {
			const url = `/companies/applications/${demand.userToOfferId}/approve`;
			await apiService.MakePutRequest(url, {}, token);
		},
		{
			onSuccess() {
				toast.success("Offre approuvée");
				queryClient.invalidateQueries("fetchDemands");
			},
			onError() {
				toast.error("L'offre n'a pas été approuvée");
			},
		}
	);

	const deleteDemandMutation = useMutation(
		async ({ offerId }: { offerId: string }) => {
			await apiService.MakePatchRequest(
				`/user/cancel-order/${offerId}`,
				{},
				token
			);
		},
		{
			onSuccess() {
				toast.success("Offre annulée avec succès");
			},
			onError(error) {
				console.log(error);
				toast.error("L'offre n'a pas été annulée");
			},
		}
	);

	const approveHandler = () => {
		// @approve functionality
		approveDemandMutation.mutate();
	};
	const deleteHandler = async () => {
		// deleteDemandMutation.mutate({ offerId: demand.offer.id });
	};

	return (
		<CTableRow
			style={{
				borderLeft: isChecked ? "3px solid #2acc96" : "",
				width: "100%",
				position: "relative",
			}}
		>
			<CTableDataCell className="px-3">
				<div className="d-flex align-items-center gap-3">
					<CFormCheck
						id="flexCheckDefault"
						label=""
						defaultChecked={isChecked}
						onChange={(e) => setIsChecked(e.target.checked)}
						color="#2acc96"
					/>
					<img
						src={demand.offer.job.avatar}
						alt=""
						width={30}
						height={30}
						style={{ borderRadius: "100%" }}
					/>
				</div>
			</CTableDataCell>
			<CTableDataCell className="p-3">{demand.offer.job.name}</CTableDataCell>
			<CTableDataCell className="p-3">
				{new Date(demand.createdAt).toDateString()}
			</CTableDataCell>
			<CTableDataCell className="p-3">{demand.availability}</CTableDataCell>
			<CTableDataCell className="p-3">
				{demand.offer.state.label !== DEMAND_STATUS.published ? (
					<p
						style={{
							color: getOperationStatusColor(demand.offer.state.label),
						}}
						className="text-center"
					>
						{demand.offer.state.label}
					</p>
				) : !approveDemandMutation.isLoading ? (
					<button
						onClick={approveHandler}
						className="btn btn-success green-shadow w-100 text-center text-white rounded-pill"
					>
						Approuver
					</button>
				) : (
					<CSpinner size="sm" color="success" />
				)}
			</CTableDataCell>
			<CTableDataCell className="p-3">
				{/* <Link to={`/demands/edit/${demand.offer.id}`} className="btn">
					<svg
						width="25"
						height="25"
						viewBox="0 0 30 30"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<rect
							x="0.5"
							y="0.5"
							width="29"
							height="29"
							rx="7.5"
							stroke="#29129D"
						/>
						<path
							d="M23.71 10.0425C24.1 9.6525 24.1 9.0025 23.71 8.6325L21.37 6.2925C21 5.9025 20.35 5.9025 19.96 6.2925L18.12 8.1225L21.87 11.8725L23.71 10.0425ZM6 20.2525V24.0025H9.75L20.81 12.9325L17.06 9.1825L6 20.2525Z"
							fill="#29129D"
						/>
					</svg>
				</Link> */}
				{deleteDemandMutation.isLoading ? (
					<CSpinner size="sm" color="danger" />
				) : (
					<button
						className="btn outline-none border-0"
						onClick={deleteHandler}
						disabled
					>
						<svg
							width="25"
							height="25"
							viewBox="0 0 30 30"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M17.3596 20.3025V11.2789C17.3596 10.8813 17.0374 10.5589 16.6396 10.5589C16.242 10.5589 15.9196 10.8811 15.9196 11.2789V20.3025C15.9196 20.7004 16.2418 21.0225 16.6396 21.0225C17.0374 21.0225 17.3596 20.7004 17.3596 20.3025ZM14.0787 20.3025V11.2789C14.0787 10.8813 13.7565 10.5589 13.3587 10.5589C12.9611 10.5589 12.6386 10.8811 12.6386 11.2789V20.3025C12.6386 20.7004 12.9609 21.0225 13.3587 21.0225C13.7563 21.0225 14.0787 20.7004 14.0787 20.3025ZM20.584 10.5585C20.1864 10.5585 19.864 10.8808 19.864 11.2785V22.1775C19.864 22.4697 19.6266 22.7077 19.3348 22.7077L10.6643 22.7075C10.372 22.7075 10.1341 22.4695 10.1341 22.1774V11.2788C10.1341 10.8812 9.81188 10.5588 9.4141 10.5588C9.0165 10.5588 8.69406 10.881 8.69406 11.2788V22.1778C8.69406 23.2638 9.57799 24.1479 10.6642 24.1479H19.3342C20.42 24.1479 21.3034 23.2638 21.3034 22.1778L21.3036 11.2788C21.3039 10.8809 20.9815 10.5588 20.5839 10.5588L20.584 10.5585ZM22.1824 8.31518H18.9226V6.57209C18.9226 6.17449 18.6004 5.85205 18.2026 5.85205H11.7955C11.3979 5.85205 11.0754 6.17431 11.0754 6.57209V8.31518H7.81575C7.41815 8.31518 7.0957 8.63744 7.0957 9.03522C7.0957 9.43282 7.41797 9.75526 7.81575 9.75526H22.1823C22.5799 9.75526 22.9024 9.433 22.9024 9.03522C22.9022 8.63762 22.5799 8.31518 22.1823 8.31518H22.1824ZM17.4827 8.31518H12.5156V7.29214H17.4823V8.31518H17.4827Z"
								fill="#FC376B"
							/>
							<rect
								x="0.5"
								y="0.5"
								width="29"
								height="29"
								rx="7.5"
								stroke="#FC376B"
							/>
						</svg>
					</button>
				)}
			</CTableDataCell>
		</CTableRow>
	);
}
export default function DemandsTable({ demands }: { demands: Demands[] }) {
	return (
		<>
			<div className="mt-3">
				<CTable>
					<CTableHead>
						<CTableRow>
							<CTableHeaderCell scope="col">Icons</CTableHeaderCell>
							<CTableHeaderCell scope="col">Jobs</CTableHeaderCell>
							<CTableHeaderCell scope="col">Date</CTableHeaderCell>
							<CTableHeaderCell scope="col">Time</CTableHeaderCell>
							<CTableHeaderCell scope="col" className="text-center">
								Status
							</CTableHeaderCell>
							<CTableHeaderCell scope="col"></CTableHeaderCell>
						</CTableRow>
					</CTableHead>
					<CTableBody>
						{demands.map((demand, index) => (
							<TableBodyRowContent demand={demand} key={index} />
						))}
					</CTableBody>
				</CTable>
			</div>
		</>
	);
}
