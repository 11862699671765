import React, { useMemo, useState } from "react";
import Pagination from "../../components/Pagination";
import AppHeader from "../../components/ui/AppHeader";
import BoxWrapper from "../../components/ui/BoxWrapper";
import DeleteIcon from "../../components/ui/icons/DeleteIcon";
import DemandIcon from "../../components/ui/icons/DemandIcon";
import DemandsTable from "./DemandsTable";
import { useQuery } from "react-query";
import { useCookie } from "../../hooks/useCookie";
import apiService from "../../service/api.service";
import { Messages } from "../../lang/content";

const PAGE_SIZE = 8;

export default function Demands() {
	const [currentPage, setCurrentPage] = useState(1);
	const { token } = useCookie();
	const { data, isLoading } = useQuery(["fetchDemands"], () => {
		return apiService.MakeGetRequest("/companies/applications", token);
	});
	const demands = useMemo(() => {
		const firstPageIndex = (currentPage - 1) * PAGE_SIZE;
		const lastPageIndex = firstPageIndex + PAGE_SIZE;
		return data?.items?.slice(firstPageIndex, lastPageIndex);
	}, [currentPage, data?.items]);

	return (
		<>
			<AppHeader showSearchInput={false} title="Demandes" />
			<BoxWrapper>
				<div className="d-flex  justify-content-between">
					<div className="d-flex align-items-center gap-2 p-3 w-50">
						<button
							disabled
							className="outline-none btn btn-outline-danger rounded-pill text-danger d-flex align-items-center gap-2 justify-content-center red-shadow"
						>
							<DeleteIcon /> Delete Demandes
						</button>
					</div>

					<div
						className="d-flex align-items-center justify-content-center gap-3 green-gradient p-3"
						style={{ borderTopRightRadius: "8px" }}
					>
						<DemandIcon fill="black" />
						<p className="fs-5 fw-bold m-0">{demands?.length} Demandes</p>
					</div>
				</div>
				{demands?.length > 0 ? (
					<DemandsTable demands={demands} />
				) : (
					!isLoading && (
						<p className="mx-auto my-4 text-center pb-3">
							{Messages.fr.content.NoDemands}
						</p>
					)
				)}
			</BoxWrapper>
			{!isLoading && (
				<Pagination
					currentPage={currentPage}
					totalCount={data?.items?.length}
					pageSize={PAGE_SIZE}
					onPageChange={(page) => setCurrentPage(page)}
					siblingCount={1}
				/>
			)}
		</>
	);
}
