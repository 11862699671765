export default function StatsIcon({ fill = "white" }) {
	return (
		<svg
			width="25"
			height="25"
			viewBox="0 0 45 45"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6.75 38.25H45V45H0V0H6.75V38.25ZM11.25 18H18V33.75H11.25V18ZM22.5 9H29.25V33.75H22.5V9ZM33.75 0H40.5V33.75H33.75V0Z"
				fill={fill}
			/>
		</svg>
	);
}
