import { CSpinner } from "@coreui/react";
import { ReactNode, useEffect, useRef } from "react";
import { withCookies, ReactCookieProps } from "react-cookie";
import jwtDecode from "jwt-decode";
import { useAuthContext } from "../../context/auth";
import { fetchUserProfile } from "../../lib/auth";
import { useNavigate } from "react-router-dom";

type Props = {
	route: string;
	children: ReactNode | JSX.Element;
};

export enum ROLES {
	ADMIN = "Admin",
	USER = "user",
	COMPANY = "Company",
}

function ProtectedRoute<T extends ReactCookieProps & Props>(props: any) {
	const { authPageLoading, setUser } = useAuthContext();
	const navigation = useNavigate();
	const runEffect = useRef(true);
	const { cookies } = props;

	const logout = () => {
		setUser(null);
		cookies?.remove("9token");
		navigation("/");
	};

	useEffect(() => {
		if (runEffect.current) {
			(async () => {
				const token = cookies?.get("9token");
				try {
					// @persist logged in user state
					// @desc: decode token to get userId
					const decodedValues: any = jwtDecode(token);
					const userProfile = await fetchUserProfile(token, decodedValues.id);

					if (userProfile && props.route === "Guest") {
						return navigation("/bricoleurs");
					}
					if (!userProfile && props.route === "Auth") {
						return navigation("/");
					}
					setUser(userProfile);
				} catch (error) {
					logout();
				}
			})();
		}
		runEffect.current = false;
	}, [props]);
	return authPageLoading ? (
		<div className="d-flex vh-100 w-100 align-items-center justify-content-center">
			<CSpinner color="success" />
		</div>
	) : (
		<div>{props.children}</div>
	);
}

export default withCookies(ProtectedRoute);
