import axios from "axios";

const getApiUrl = () => process.env.REACT_APP_API_URL;
class ApiRequest {
	baseUrl;
	constructor() {
		this.baseUrl = getApiUrl();
	}

	headers(token: string) {
		return {
			headers: {
				Authorization: `Bearer ${token ? token : ""}`,
			},
		};
	}
	async MakeGetRequest(url: string, token = "") {
		const endpoint = this.baseUrl + url;

		const { data } = await axios.get(endpoint, this.headers(token));
		return data;
	}
	async MakePostRequest(url: string, postData: unknown, token = "") {
		const endpoint = this.baseUrl + url;
		const { data } = await axios.post(endpoint, postData, this.headers(token));
		return data;
	}
	async MakePutRequest(url: string, putData: unknown, token = "") {
		const endpoint = this.baseUrl + url;
		const { data } = await axios.put(endpoint, putData, this.headers(token));
		return data;
	}
	async MakePatchRequest(url: string, patchData: unknown, token = "") {
		const endpoint = this.baseUrl + url;
		const { data } = await axios.patch(
			endpoint,
			patchData,
			this.headers(token)
		);
		return data;
	}
}

export default new ApiRequest();
