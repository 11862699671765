export default function CameraIcon() {
	return (
		<svg
			width="25"
			height="25"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="20" cy="20" r="20" fill="url(#paint0_linear_6131_8400)" />
			<path
				d="M27.6875 13.9245H24.5885L23.8502 11.8555C23.7992 11.7139 23.7057 11.5916 23.5826 11.5051C23.4594 11.4186 23.3126 11.3723 23.1621 11.3724H16.1712C15.8636 11.3724 15.5879 11.5661 15.4853 11.8555L14.7448 13.9245H11.6458C10.6386 13.9245 9.82288 14.7403 9.82288 15.7474V26.138C9.82288 27.1452 10.6386 27.961 11.6458 27.961H27.6875C28.6946 27.961 29.5104 27.1452 29.5104 26.138V15.7474C29.5104 14.7403 28.6946 13.9245 27.6875 13.9245ZM19.6666 24.3151C17.6523 24.3151 16.0208 22.6836 16.0208 20.6693C16.0208 18.655 17.6523 17.0235 19.6666 17.0235C21.6809 17.0235 23.3125 18.655 23.3125 20.6693C23.3125 22.6836 21.6809 24.3151 19.6666 24.3151ZM17.4791 20.6693C17.4791 21.2495 17.7096 21.8059 18.1198 22.2161C18.5301 22.6263 19.0865 22.8568 19.6666 22.8568C20.2468 22.8568 20.8032 22.6263 21.2134 22.2161C21.6237 21.8059 21.8541 21.2495 21.8541 20.6693C21.8541 20.0891 21.6237 19.5327 21.2134 19.1225C20.8032 18.7123 20.2468 18.4818 19.6666 18.4818C19.0865 18.4818 18.5301 18.7123 18.1198 19.1225C17.7096 19.5327 17.4791 20.0891 17.4791 20.6693Z"
				fill="white"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_6131_8400"
					x1="0"
					y1="20"
					x2="40"
					y2="20"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FF8008" />
					<stop offset="1" stopColor="#FFC837" />
				</linearGradient>
			</defs>
		</svg>
	);
}
