export default function RadioCheckedIcon({ width = "30", height = "30" }) {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="15" cy="15" r="15" fill="#2ACC96" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.6667 21.8667L6 15.2L7.86667 13.3333L12.6667 18.1333L22.8 8L24.6667 9.86667L12.6667 21.8667Z"
				fill="white"
			/>
		</svg>
	);
}
