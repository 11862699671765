import React from "react";
import { APP_COLORS } from "../helpers/constants";
import { usePagination } from "../hooks/usePagination";

interface propsType {
	onPageChange: (page: number) => void;
	totalCount: number;
	siblingCount: number;
	currentPage: number;
	pageSize: number;
}
export default function Pagination({
	onPageChange,
	totalCount,
	siblingCount = 1,
	currentPage,
	pageSize,
}: propsType) {
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	});
	// Hide pagination iff there are less than 2 times in pagination range
	if (currentPage === 0 || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};
	let lastPage = paginationRange[paginationRange.length - 1];
	return (
		<ul className="d-flex justify-content-end gap-1 px-3">
			{currentPage !== 1 && (
				<li
					style={{
						color: APP_COLORS.gray,
					}}
					onClick={onPrevious}
				>
					Previous
				</li>
			)}
			{paginationRange.map((pageNumber) => {
				return (
					<li
						style={{
							color: APP_COLORS.gray,
							border: `${
								pageNumber === currentPage ? "1px solid #2acc96" : ""
							}`,
							width: "20px",
							height: "20px",
							marginTop: "2px",
						}}
						onClick={() => onPageChange(Number(pageNumber))}
						className="fw-bold d-flex justify-content-center align-items-center"
					>
						{pageNumber}
					</li>
				);
			})}
			{currentPage !== lastPage && (
				<li
					style={{
						color: APP_COLORS.gray,
					}}
					onClick={onNext}
				>
					Next
				</li>
			)}
		</ul>
	);
}
