import { APP_COLORS, DEMAND_STATUS, TRANSACTIONS_STATUS } from "./constants";

export const getOperationStatusColor = (status: string) => {
	switch (status.toLowerCase()) {
		case DEMAND_STATUS.cancelled.toLowerCase() ||
			TRANSACTIONS_STATUS.cancelled.toLowerCase():
			return APP_COLORS.red;
		case DEMAND_STATUS.published.toLowerCase():
		case DEMAND_STATUS.finished.toLowerCase() ||
			TRANSACTIONS_STATUS.completed.toLowerCase():
			return APP_COLORS.green;
		case TRANSACTIONS_STATUS.processing.toLowerCase():
			return APP_COLORS.yellow;
	}
};
