import { CContainer, CFormCheck, CSpinner } from "@coreui/react";
import { FormEvent, useState } from "react";
import { useCookies } from "react-cookie";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../components/ui/icons/Logo";
import Paragraph from "../components/ui/Paragraph";
import { useAuthContext } from "../context/auth";
import apiService from "../service/api.service";
import ProtectedRoute, { ROLES } from "./auth/ProtectedRoute";
import { toast } from "react-toastify";
import { Messages } from "../lang/content";

export default function Login() {
	const { setUser } = useAuthContext();
	const [connecting, setConnecting] = useState(false);
	const [, setCookies] = useCookies(["9token"]);

	const navigate = useNavigate();

	const attemptLogin = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const formData = new FormData(e.currentTarget);

		const userData = {
			email: formData?.get("email"),
			password: formData?.get("password"),
		};
		try {
			setConnecting(true);
			const user = await apiService.MakePostRequest("/user/login", userData);

			if (user?.roles?.name?.toLowerCase() !== ROLES.COMPANY.toLowerCase()) {
				toast.error(Messages.fr.auth.accessDenied);
				return;
			}
			setUser(user);
			setCookies("9token", user.token, {
				path: "/",
			});
			navigate("/bricoleurs");
		} catch (error) {
			setUser(null);
		} finally {
			setConnecting(false);
		}
	};
	return (
		<ProtectedRoute route="Guest">
			<CContainer>
				<div className="d-flex flex-column align-items-center w-100 pt-5">
					<form
						method="post"
						className="login-form-wrapper"
						onSubmit={attemptLogin}
					>
						<div className="d-flex flex-column align-items-center">
							<Logo width={160} height={160} />
							<Paragraph className="fw-bold mb-3">
								Connectez-vous à votre compte
							</Paragraph>
							<Paragraph className="text-light">
								Tableau de bord de l'Admin
							</Paragraph>
						</div>
						<div className="mt-2 mb-4">
							<label htmlFor="email" className="d-block mb-1">
								Email
							</label>
							<input
								type="email"
								name="email"
								id="email"
								placeholder="companyName@example.com"
								className="rounded-sm p-2 w-100 outline-none border border-light shadow-sm"
							/>
						</div>
						<div className="mb-2">
							<label htmlFor="password" className="d-block mb-1">
								Mot de passe
							</label>
							<input
								placeholder="******"
								type="password"
								name="password"
								id="password"
								className="rounded-sm p-2 w-100 outline-none border border-light shadow-sm"
							/>
						</div>
						<div className="d-flex align-items-center justify-content-between">
							<CFormCheck label="Rappelez-vous de moi" className="text-sm" />
							<Link to="/forget-password" className="text-success text-sm mb-1">
								Mot de passe oublié ?
							</Link>
						</div>
						<button
							type="submit"
							className="btn btn-success w-100 mt-3 text-white p-2"
							disabled={connecting}
						>
							{connecting ? (
								<CSpinner color="dark" size="sm" />
							) : (
								"SE CONNECTER"
							)}
						</button>
					</form>
				</div>
			</CContainer>
		</ProtectedRoute>
	);
}
