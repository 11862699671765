import { CNavItem, CSidebar, CSidebarNav } from "@coreui/react";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import AccountIcon from "./icons/AccountIcon";
import DemandIcon from "./icons/DemandIcon";
import DiyIcon from "./icons/DiyIcon";
import JobsIcon from "./icons/JobsIcon";
import Logo from "./icons/Logo";
import StatsIcon from "./icons/StatsIcon";
import UsersIcon from "./icons/UsersIcon";
import WithdrawIcon from "./icons/WithdrawIcon";
import { useAuthContext } from "../../context/auth";
import LogoutIcon from "./icons/LogoutIcon";
import LogoutModal from "./modals/LogoutModal";

export default function MainSideBar() {
	const [showLogoutModal, setShowLogoutModal] = useState(false);
	const pathName = useLocation()?.pathname;
	const { user } = useAuthContext();

	return (
		<CSidebar
			style={{
				background:
					"linear-gradient(90deg, #141E30 0%, #141E30 0.01%, #243B55 100%)",
			}}
			className="p-4 vh-100 position-sticky top-0 left-0"
		>
			<Logo width={140} height={140} fill="white" />
			<CSidebarNav className="mt-3">
				<CNavItem
					href="/bricoleurs"
					className="text-white mb-3 d-flex gap-3 fw-bold fs-5 sideNav_menu"
				>
					<DiyIcon
						fill={pathName.includes("/bricoleurs") ? "#2acc96" : "white"}
					/>
					Bricoleurs
				</CNavItem>

				<CNavItem
					href="/account"
					className="text-white mb-3 d-flex gap-3 fw-bold fs-5 sideNav_menu"
				>
					<AccountIcon
						fill={pathName.includes("/account") ? "#2acc96" : "white"}
					/>
					Account
				</CNavItem>

				<CNavItem
					href="/demands"
					className="text-white mb-3 d-flex gap-3 fw-bold fs-5 sideNav_menu"
					style={{ marginLeft: "7px" }}
				>
					<DemandIcon
						fill={pathName.includes("/demands") ? "#2acc96" : "white"}
					/>
					Demandes
				</CNavItem>
				<CNavItem
					href="/stats"
					className="text-white mb-3 d-flex gap-3 fw-bold fs-5 sideNav_menu"
					style={{ marginLeft: "7px" }}
				>
					<StatsIcon fill={pathName.includes("/stats") ? "#2acc96" : "white"} />
					Statistiques
				</CNavItem>
				<CNavItem
					href="/withdraw"
					className="text-white mb-3 d-flex gap-3 fw-bold fs-5 sideNav_menu"
					style={{ marginLeft: "7px" }}
				>
					<WithdrawIcon
						fill={pathName.includes("/withdraw") ? "#2acc96" : "white"}
					/>
					Retrait
				</CNavItem>
			</CSidebarNav>
			<div className="d-flex  align-items-center mb-3">
				<div
					style={{ width: "50px", height: "50px", marginRight: "10px" }}
					className="photo-bg"
				>
					{user?.avatar ? (
						<img
							src={user?.avatar}
							alt="profile-pic"
							style={{ height: "100%", width: "100%", objectFit: "cover" }}
							className="photo-placeholder"
						/>
					) : (
						<div className="bg-white h-100 w-100 photo-placeholder" />
					)}
				</div>
				<div style={{ marginRight: "40px" }}>
					<h4 className="text-blue ml-1">
						{user?.firstName} {user?.lastName}
					</h4>
					<p className="fw-bold text-success">Admin</p>
				</div>
				<button
					className="btn outline-none"
					onClick={() => setShowLogoutModal(true)}
				>
					<LogoutIcon />
				</button>
				{/* profile photo here */}
			</div>
			<LogoutModal
				visible={showLogoutModal}
				onClose={() => setShowLogoutModal(false)}
			/>
		</CSidebar>
	);
}
