export const Messages = {
	fr: {
		password: {
			misMatch:
				"Le nouveau mot de passe n'est pas le même que le mot de passe de confirmation",
		},
		content: {
			NoWorkers: "Il n'y a pas de travailleurs associés à votre entreprise",
			NoDemands: "Désolé, vous n'avez aucune demande",
			loggingInOut: "Fermeture de la session",
			FailedToApprove: "N'a pas approuvé ce bricoleur",
			FailedToDelete: "Échec de la suppression de ce bricoleur",
			FailedToLoadStats: "Échec du chargement des statistiques de l'entreprise",
			FailedToLoadTransactions:
				"Impossible de charger l'historique des transactions",
			FailedToSavePhoto: "Échec de la mise à jour de la photo",
		},
		account: {
			RIBAdded: "RIB ajouté avec succès",
			FailedToAddRIB: "Échec de l'ajout de RIB ",
		},
		auth: {
			accessDenied: "Accès refusé",
		},
	},
};
