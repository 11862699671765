import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

export const useCookie = () => {
	const [cookies, setCookies, removeCookie] = useCookies(["9token"]);
	const [id, setId] = useState("");

	useEffect(() => {
		const decodedValue: any = jwtDecode(cookies["9token"]);
		setId(decodedValue.id);
	}, [cookies]);
	return { token: cookies["9token"], setCookies, id, removeCookie };
};
