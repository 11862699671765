export default function LogoutIcon() {
	return (
		<svg
			width="35"
			height="35"
			viewBox="0 0 50 50"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22.2906 41.395H14.5456C10.6715 41.395 7.51862 38.2422 7.51862 34.3649V16.7238C7.51862 12.8466 10.6715 9.69214 14.5456 9.69214H22.2731C26.1504 9.69214 29.3048 12.8466 29.3048 16.7238V18.2012C29.3048 18.8574 28.7722 19.3901 28.116 19.3901C27.4597 19.3901 26.9271 18.8574 26.9271 18.2012V16.7238C26.9271 14.1559 24.8395 12.0699 22.2731 12.0699H14.5456C11.9824 12.0699 9.89633 14.1559 9.89633 16.7238V34.3649C9.89633 36.9313 11.9824 39.0173 14.5456 39.0173H22.2906C24.8458 39.0173 26.9271 36.9376 26.9271 34.3824V32.8876C26.9271 32.2313 27.4597 31.6987 28.116 31.6987C28.7722 31.6987 29.3048 32.2313 29.3048 32.8876V34.3824C29.3048 38.2501 26.1568 41.395 22.2906 41.395Z"
				fill="white"
			/>
			<mask
				id="mask0_6131_9126"
				maskUnits="userSpaceOnUse"
				x="18"
				y="24"
				width="23"
				height="3"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M18.608 24.3547H40.0723V26.7325H18.608V24.3547Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask0_6131_9126)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M38.8836 26.7325H19.7968C19.1406 26.7325 18.608 26.1998 18.608 25.5436C18.608 24.8873 19.1406 24.3547 19.7968 24.3547H38.8836C39.5398 24.3547 40.0724 24.8873 40.0724 25.5436C40.0724 26.1998 39.5398 26.7325 38.8836 26.7325Z"
					fill="#2ACC96"
				/>
			</g>
			<mask
				id="mask1_6131_9126"
				maskUnits="userSpaceOnUse"
				x="33"
				y="19"
				width="8"
				height="13"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M33.0543 19.7344H40.0723V31.3547H33.0543V19.7344Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask1_6131_9126)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M34.2427 31.3547C33.9384 31.3547 33.6324 31.239 33.401 31.0044C32.9382 30.5383 32.9397 29.787 33.4042 29.3241L37.199 25.5435L33.4042 21.7646C32.9397 21.3017 32.9366 20.5503 33.401 20.0843C33.8639 19.6183 34.6152 19.6183 35.0813 20.0811L39.7226 24.7018C39.9477 24.9237 40.0729 25.2281 40.0729 25.5435C40.0729 25.859 39.9477 26.1633 39.7226 26.3852L35.0813 31.0075C34.8498 31.239 34.5455 31.3547 34.2427 31.3547Z"
					fill="#2ACC96"
				/>
			</g>
		</svg>
	);
}
